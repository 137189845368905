export const countries = [
  {value: "Bangladesh", label: "Bangladesh"},
  {value: "India", label: "India"},
  {value: "Pakistan", label: "Pakistan"},
  {value: "Vietnam", label: "Vietnam"},
  {value: "Indonesia", label: "Indonesia"},
  {value: "Nepal", label: "Nepal"},
  {value: "Myanma", label: "Myanmar"},
  {value: "Cambodia", label: "Cambodia"}
];

export const years = [

]

for (let year = 2015; year <= 2040; year++) {
  years.push(year);
}